<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage resource</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Resource
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <div class="col-3">
                  <v-text-field label="Title" type="text"
                    dense clearable
                    v-on:keyup.enter="getResources()"
                    outlined v-model="search.name">
                  </v-text-field>
                </div>
                <div class="col-3">
                  <v-select label="Resource category"
                      outlined dense :items="resource_categories" item-text="title" item-value="id" :loading="resourceCategoryLoading"
                      v-on:keyup.enter="getResources()" v-model="search.category_id">
                  </v-select>
                </div>
                <div class="col-3">
                  <v-select label="Program"
                            outlined dense :items="programs" item-text="title" item-value="id" :loading="programs"
                            v-on:keyup.enter="getResources()" v-model="search.program_id">
                  </v-select>
                </div>
                <div class="col-2">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getResources()"
                    v-model="search.active"
                  ></v-select>
                </div>

                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    @click="getResources()"
                    class="btn btn-primary btn-search"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap">Title</th>
                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap">Total attachments</th>

                    <th>Thesis</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(resource, i) of resources" :key="i">
                    <td class="px-3" style="max-width: 300px !important; white-space: pre-wrap">
                      <span class="font-weight-bold">
                        {{ resource.title }}
                      </span>

                      <i class="mx-2 fas fa-circle" :class="resource.is_active ? 'dot-active' : 'dot-warning'"></i>
                    </td>
                    <td class="px-3">
                      <span class="font-weight-medium">
                        {{resource.available_attachments}}
                      </span>
                    </td>
                    <td class="">
                      <span>
                        <v-icon small
                          :color="resource.is_thesis ? '#0abb87' : 'red'"
                          :class="resource.is_thesis ? 'fas fa-check' : 'fas fa-ban'">
                        </v-icon>
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                     <b-dropdown v-if="resource.available_attachments > 0 "
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              href="#"
                              class="navi-link"
                              @click="manageAttachment(resource)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-file"></i>
                              </span>
                              <span class="navi-text"> View attachment</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                      <span class="font-weight-medium" v-else>N/A</span>
                    </td>
                  </tr>
                  <tr v-if="resources.length == 0">
                    <td class="text-center" colspan="10">
                      <strong>
                        No items found.
                      </strong>

                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                class="pull-right mt-7"
                v-if="resources.length > 0"
                @input="getResources"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import LibraryResourceService from "@/core/services/library/resource/LibraryResourceService";
import ResourceCategoryService from "@/core/services/library/resource/ResourceCategoryService";
import ProgramService from "@/core/services/level/program/ProgramService";

const program=new ProgramService();
const resourceCategory=new ResourceCategoryService();
const libraryResource=new LibraryResourceService();
export default {
  name: "Index",
  data() {
    return {
      search: {
        active: "active",
        category_id:null,
        name:null,
      },
      resources: [],
      resource_categories: [],
      programs: [],
      dialog: false,
      loading: false,
      programLoading: false,
      resourceCategoryLoading: false,
      page: null,
      total: null,
      perPage: null,
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
    };
  },
  mounted() {
    this.getAllActiveResourceCategory();
    this.getResources();
    this.getAllActivePrograms();
  },
  methods: {
    getAllActivePrograms(){
      this.programLoading=true;
      program
          .all()
          .then((response) => {
            this.programs = response.data;

          })
          .catch((err) => {

          })
          .finally(() => {
            this.programLoading = false;
          });
    },
    getAllActiveResourceCategory(){
      this.resourceCategoryLoading=true;
      resourceCategory
          .getAllActiveResourceCategory()
          .then((response) => {
            this.resource_categories = response.data.resource_categories;

          })
          .catch((err) => {

          })
          .finally(() => {
            this.resourceCategoryLoading = false;
          });
    },
    getResources() {
      this.loading = true;
      this.dialog = false;
      libraryResource
        .paginate(this.search, this.page)
        .then((response) => {
          this.resources = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {

        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.getResources();
    },
    manageAttachment(resource){
      this.$router.push({
          name: 'library-resource-attachment-list', params: {
          id: resource.id
        }
      })
    },

  },
};
</script>

<style scoped></style>
