import ApiService from "@/core/services/api.service";
import {API_URL} from '@/common/config.js'

const apiService = ApiService;


export default class LibraryResourceService
{
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/resource`;

    }

    paginate(data = null, index = null) {
        let url = `${this.#api}/get-by-student`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}