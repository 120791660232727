import ApiService from "@/core/services/api.service";
import { API_URL } from '@/common/config.js'
const apiService = ApiService;


export default class ResourceCategoryService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/resource-category`;

    }
    getAllActiveResourceCategory(){
        let url = `${this.#api}/active/all`
        return apiService.get(url);
    }
}